import React, { FC, ReactElement } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml'; /* eslint-disable-line import/no-unresolved */

import { IPropsFooterBottomBanner } from './model';
import './FooterBottomBanner.scss';

const FooterBottomBanner: FC<IPropsFooterBottomBanner> = ({
  footerBottomBanner,
}): ReactElement | null => (
  <div data-test="FooterBottomBanner" className="footer-bottom-banner">
    {footerBottomBanner?.[0]?.properties.text ? (
      <DangerouslySetInnerHtml html={footerBottomBanner[0].properties.text} className="text" />
    ) : null}
  </div>
);

export default FooterBottomBanner;
